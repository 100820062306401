export const navLinks = [
  {
    label: 'Apps',
    pathname: '/',
    hash: '#apps',
  },
  {
    label: 'About',
    pathname: '/',
    hash: '#details',
  },
  {
    label: 'Contact',
    pathname: '/contact',
  },
];

export const socialLinks = [
  {
    label: 'LinkedIn',
    url: 'https://www.linkedin.com/in/kajetan-kuczorski-ba74131a6/',
    icon: 'linkedin',
  },
  {
    label: 'Discord',
    url: 'https://discord.gg/yGw7mJHapa',
    icon: 'discord',
  },
  {
    label: 'Github',
    url: 'https://github.com/kajto-kczr',
    icon: 'github',
  },
];
