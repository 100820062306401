import classNames from 'classnames';
import { useId } from 'hooks';
import './index.css';

function Monogram({ highlight, className, ...props }) {
  const id = useId();
  const clipId = `monogram-clip-${id}`;

  return (
    <svg
      aria-hidden
      className={classNames('monogram', className)}
      width="46"
      height="29"
      viewBox="0 0 413.9 543.81"
      {...props}
    >
      <defs>
        <clipPath id={clipId}>
          <path class="cls-1" d="M945.5,300.5A260.82,260.82,0,0,0,878,272c-62-16.31-111.32-4.51-142,3-29.13,7.13-40.56,14.1-49,22-14.13,13.24-10.24,20.09-28,39-15.51,16.52-32.75,26.49-44,33a239.24,239.24,0,0,1-26,13c18.49-12.89,32.89-24.45,43-33,9.63-8.14,15.22-12.53,21-20,9.77-12.61,8-17,16-29,10.16-15.21,23.12-23.32,37-32a180.67,180.67,0,0,1,33-16c38.86-14.25,74.51-9.74,112-5,17.24,2.18,25.8,3.64,37,8C917.51,266.48,935.88,287.36,945.5,300.5Z" transform="translate(-578.46 -242.38)"/>
          <path id="Nase" class="cls-2" d="M597,436c-10.53-17.12-13.57-31.95-8-37,1.16-1.05,3.2-2.14,7-2v7l3,7h4a26.51,26.51,0,0,1,7-3,28.22,28.22,0,0,1,17,1l8-6a139.85,139.85,0,0,0-51-16c-1.08,1.68-8.76,14-4,29A35,35,0,0,0,597,436Z" transform="translate(-578.46 -242.38)"/>
          <path class="cls-1" d="M913,352c1.83,5.17,11.82,34.85-4,58-12.38,18.13-33.54,21.94-56,26a158.81,158.81,0,0,1-65-2l-14-21a310.48,310.48,0,0,0-55,10c-40.28,11.2-56.61,24.7-83,21-17.18-2.41-30.38-10.95-39-18l19,33a139.33,139.33,0,0,0,32,5c35.53,1.33,51.63-12.06,85-11a127.12,127.12,0,0,1,40,8c31.94,11.72,53.93,9.12,76,6,12.2-1.73,65.75-9.31,79-48C938.51,388.29,917,357.47,913,352Z" transform="translate(-578.46 -242.38)"/>
          <ellipse class="cls-1" cx="178.04" cy="97.12" rx="18.5" ry="17.5"/>
          <path class="cls-1" d="M753,469c-10.37,10.42-46.66,49.43-55,114.81-9.6,75.25,24.91,129.93,32,140.69a347.21,347.21,0,0,1,1-105.8,335.14,335.14,0,0,1,16-60.78,169.15,169.15,0,0,0-22,32.64,179.52,179.52,0,0,0-12,29.26,301.25,301.25,0,0,1,8-70.91A286.82,286.82,0,0,1,753,469Z" transform="translate(-578.46 -242.38)"/>
          <path class="cls-1" d="M938.5,402.5c9,16.85,18.86,42.05,13.5,69.5-5.9,30.19-27.4,46.3-57,73C788,641.51,761.83,681.77,750,721c-10.43,34.57-3.25,47,1,52,15.69,18.56,59.27,17.5,70-3,6.55-12.51-3.15-29.81-7-36,.49,4.62,1.61,19.71-7,26-11.33,8.28-36.5-1.3-44-16-14.68-28.79,29.68-94.37,85-130,37.4-24.09,66.27-25.77,94-62,6.42-8.38,17.73-23.19,23-45C977.4,455.71,945.55,411.78,938.5,402.5Z" transform="translate(-578.46 -242.38)"/>
          <g id="Ohr">
          <path class="cls-1" d="M931,346.62,925,323.52l27.25-18.26,26.24,3.56,13.86,18.38-12.45,37L945,398.84l-14.13-30.09,15.52-8.48L937.83,370l4.71,11.08,27.58-22.36,5.78-26.55-21.78-13.66-21.54,10.68Z" transform="translate(-578.46 -242.38)"/>
          </g>
          <text x="-578.46" y="-242.38"/>
          </clipPath>
      </defs>
      <rect clipPath={`url(#${clipId})`} width="100%" height="100%" />
      {highlight && (
        <g clipPath={`url(#${clipId})`}>
          <rect className="monogram__highlight" width="100%" height="100%" />
        </g>
      )}
    </svg>
  );
}

export default Monogram;

